import { useFlag } from '@axteams-one/bws-cloud-flags/react'
import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import {
  Avatar,
  AvatarGroup,
  AvatarGroupItem,
  AvatarGroupPopover,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  MenuOpenChangeData,
  MenuOpenEvent,
  MenuPopover,
  MenuTrigger,
  Text,
  makeStyles,
  mergeClasses,
  partitionAvatarGroupItems,
  tokens,
} from '@fluentui/react-components'
import {
  Delete20Regular,
  Edit20Regular,
  Map20Regular,
  MoreHorizontal20Regular,
  PeopleTeam20Regular,
  Pin20Filled,
  Pin20Regular,
} from '@fluentui/react-icons'
import { Temporal } from '@js-temporal/polyfill'
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flags } from '../util/flags'
import {
  Bearer,
  Group,
  bearerIdsFromListToString,
  groupNameLabel,
} from '../util/group'
import { subjectFromBearerId } from '../util/map'
import { GroupDialogState } from './StreamList'
import TimeLabel from './TimeLabel'

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: tokens.spacingVerticalXS,
    padding: tokens.spacingHorizontalXS,
    paddingInlineEnd: tokens.spacingHorizontalS,
    color: tokens.colorNeutralForeground2,
    '@media(hover: hover) and (pointer: fine)': {
      ':hover': {
        color: tokens.colorNeutralForeground1,
        backgroundColor: tokens.colorNeutralBackground3Hover,
        '& .mapButton, & .moreIcon': {
          display: 'flex',
        },
        '& .timeLabel': {
          display: 'none',
        },
      },
    },
  },
  link: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    color: tokens.colorNeutralForeground2,
  },
  // Style selected group
  selected: {
    color: tokens.colorNeutralForeground1,
    backgroundColor: tokens.colorNeutralBackground1Selected,
  },
  // Style group with menu open
  menuOpen: {
    color: tokens.colorNeutralForeground1,
    backgroundColor: tokens.colorNeutralBackground1Selected,
    '& .mapButton': {
      display: 'flex',
    },
    '& .moreIcon': {
      display: 'flex',
      color: tokens.colorNeutralStrokeAccessibleSelected,
    },
    '& .timeLabel': {
      display: 'none',
    },
  },
  name: {
    width: '100%',
    marginInlineStart: tokens.spacingHorizontalXS,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    pointerEvents: 'none',
  },
  mapButton: {
    flexShrink: 0,
    display: 'none',
    color: tokens.colorNeutralStrokeDisabled,
    marginInlineStart: tokens.spacingHorizontalXS,
    cursor: 'not-allowed',
  },
  enabledMapButton: {
    color: tokens.colorNeutralForeground2,
    cursor: 'pointer',
    ':hover': {
      color: tokens.colorNeutralStrokeAccessibleSelected,
    },
  },
  moreIcon: {
    flexShrink: 0,
    display: 'none',
    marginInlineStart: tokens.spacingHorizontalXS,
    ':hover': {
      color: tokens.colorNeutralStrokeAccessibleSelected,
    },
  },
})

type GroupAvatarProps = {
  group: Group
}

type GroupItemProps = {
  group: Group
  selected: boolean
  positions: Position[]
  pinned?: boolean
  triggerTimestamp?: Temporal.ZonedDateTime
  setDialogState: Dispatch<SetStateAction<GroupDialogState>>
  onMapButtonClick: (groupId: string) => void
  onClick: (event: MouseEvent<HTMLDivElement>, groupId: string) => void
  onPointerOver: (group: Group) => void
  onDrop: (groupId: string) => void
  onPin: (id: string) => void
}

export default function GroupItem({
  group,
  selected,
  positions,
  pinned = false,
  triggerTimestamp,
  setDialogState,
  onMapButtonClick,
  onClick,
  onPointerOver,
  onPin,
  onDrop,
}: GroupItemProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  const [menuOpen, setMenuOpen] = useState(false)
  const enablePinGroups = useFlag(Flags.PIN_GROUPS)?.enabled === true
  const hasPosition = [...group.bearers.values()].some((bearer: Bearer) =>
    positions.find(
      (position: Position) =>
        position.subject === subjectFromBearerId(bearer.id)
    )
  )

  const bearers = group.bearers
  const bearerIds = [...bearers.keys()]
  const bearerIdsParam = bearerIdsFromListToString(bearerIds)
  const to =
    bearerIds.length > 0
      ? `/streams/group/${group.id}/${bearerIdsParam}`
      : `/streams/group/${group.id}/`

  return (
    <div
      id="groupItem"
      key={group.id}
      tabIndex={-1}
      color={tokens.colorBrandForeground1}
      className={className()}
      title={group.name}
      onClick={(event) => onClick(event, group.id)}
      onPointerOver={() => onPointerOver(group)}
      onDrop={() => onDrop(group.id)}
      onDragOver={(event) => {
        event.preventDefault()
      }}
    >
      <Link to={to} className={styles.link}>
        <GroupAvatar group={group} />
        <Text className={styles.name}>{groupNameLabel(group)}</Text>
        <TimeLabel timestamp={triggerTimestamp} />
      </Link>
      <Map20Regular
        className={mapButtonClassName(hasPosition)}
        onClick={() => hasPosition && onMapButtonClick(group.id)}
      />
      <Menu onOpenChange={onOpenChange}>
        <MenuTrigger disableButtonEnhancement>
          <MoreHorizontal20Regular
            color={tokens.colorNeutralForeground1}
            className={mergeClasses(styles.moreIcon, 'moreIcon')}
            aria-label={t('common.more-options')}
          />
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuGroup>
              <MenuItem
                icon={<Edit20Regular />}
                onClick={() => {
                  setDialogState({
                    openDialog: 'edit',
                    group: group,
                  })
                }}
              >
                Edit
              </MenuItem>
              {enablePinGroups && (
                <MenuItem
                  icon={pinned ? <Pin20Filled /> : <Pin20Regular />}
                  onClick={() => onPin(group.id)}
                >
                  {pinned ? t('streams.unpin') : t('streams.pin')}
                </MenuItem>
              )}
            </MenuGroup>
            <MenuDivider />
            <MenuGroup>
              <MenuItem
                icon={<Delete20Regular />}
                onClick={() => {
                  setDialogState({
                    openDialog: 'remove',
                    group: group,
                  })
                }}
              >
                Remove
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  )

  function className() {
    let classes = styles.container
    if (selected) {
      classes = mergeClasses(classes, styles.selected)
    }

    if (menuOpen) {
      classes = mergeClasses(classes, styles.menuOpen)
    }

    return classes
  }

  function mapButtonClassName(hasPosition: boolean) {
    let classes = mergeClasses(styles.mapButton, 'mapButton')
    if (hasPosition) {
      classes = mergeClasses(classes, styles.enabledMapButton)
    }
    return classes
  }

  function onOpenChange(_event: MenuOpenEvent, data: MenuOpenChangeData) {
    setMenuOpen(data.open)
  }
}

function GroupAvatar({ group }: GroupAvatarProps) {
  const { t } = useTranslation()
  const bearerNames = [...group.bearers.values()].map((bearer) => bearer.name)
  if (bearerNames.length === 0) {
    return (
      <Avatar
        size={24}
        icon={<PeopleTeam20Regular />}
        aria-label={t('streams.group')}
      />
    )
  }

  const { inlineItems, overflowItems } = partitionAvatarGroupItems({
    items: bearerNames,
    layout: 'pie',
  })

  return (
    <AvatarGroup layout="pie" size={24} key={group.id}>
      {inlineItems.map((name) => (
        <AvatarGroupItem name={name} key={name} />
      ))}
      {overflowItems && (
        <AvatarGroupPopover
          tooltip={{
            content: t('streams.view-more-users'),
            relationship: 'label',
          }}
        >
          {overflowItems.map((name) => (
            <AvatarGroupItem name={name} key={name} />
          ))}
        </AvatarGroupPopover>
      )}
    </AvatarGroup>
  )
}
