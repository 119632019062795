import { useCallback, useEffect, useState } from 'react'
import { Client } from 'urql'

import {
  DashCryptoContextV1,
  GetStreamsDocument,
  GetStreamsQuery,
} from '../graphql/__generated__/graphql'
import { streamMapper } from '../util/mapper'
import { Stream, compareTriggerTimestamp } from '../util/stream'

type useGetStreamsProps = {
  client?: Client
  organizationId: string
  recipients?: string[]
}

function useGetStreams({
  client,
  organizationId,
  recipients,
}: useGetStreamsProps): [Stream[] | undefined, () => void] {
  const [streams, setStreams] = useState<Stream[]>()

  const getStreams: () => void = useCallback(() => {
    if (!client) {
      return
    }

    client
      .query<GetStreamsQuery>(
        GetStreamsDocument,
        {
          options: { organizationId, recipients },
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          console.error(result.error.message)
        } else {
          const data = result.data?.getStreams

          if (data && organizationId) {
            const streams = data.map(streamMapper)
            streams.sort(compareTriggerTimestamp)
            streams.filter((stream) => {
              // Only handle V1 for now. Only include streams that the client can decrypt.
              // TODO: Remove this filter and handle the encrypted streams in the UI.
              if (stream.dash?.crypto?.version === '1') {
                const crypto = stream.dash?.crypto as DashCryptoContextV1
                const streamKeys = Object.keys(crypto?.keys ?? {})

                return streamKeys.some((key) => recipients?.includes(key))
              } else {
                return true
              }
            })
            setStreams(streams)
          }
        }
      })
  }, [client, organizationId, recipients])

  useEffect(() => {
    getStreams()
  }, [getStreams])

  return [streams, getStreams]
}

export { useGetStreams }
