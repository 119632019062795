import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import {
  Avatar,
  Text,
  Tooltip,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { Map20Regular } from '@fluentui/react-icons'
import { DragEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { Stream } from '../util/stream'
import TimeLabel from './TimeLabel'

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    borderRadius: tokens.spacingVerticalXS,
    padding: tokens.spacingHorizontalXS,
    paddingInlineEnd: tokens.spacingHorizontalS,
    color: tokens.colorNeutralForeground2,
    '@media(hover: hover) and (pointer: fine)': {
      ':hover': {
        color: tokens.colorNeutralForeground1,
        backgroundColor: tokens.colorNeutralBackground3Hover,
        '& .mapButton': {
          display: 'flex',
        },
        '& .timeLabel': {
          display: 'none',
        },
      },
    },
  },
  // Style stream item if hovered on map
  hovered: {
    color: tokens.colorNeutralForeground1,
    backgroundColor: tokens.colorNeutralBackground3Hover,
  },
  name: {
    width: '100%',
    marginInlineStart: tokens.spacingHorizontalXS,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    pointerEvents: 'none',
  },
  mapButton: {
    flexShrink: 0,
    display: 'none',
    color: tokens.colorNeutralStrokeDisabled,
    marginInlineStart: tokens.spacingHorizontalXS,
    cursor: 'not-allowed',
  },
  enabledMapButton: {
    color: tokens.colorNeutralForeground2,
    cursor: 'pointer',
    ':hover': {
      color: tokens.colorNeutralStrokeAccessibleSelected,
    },
  },
})

type StreamItemProps = {
  stream: Stream
  position: Position | undefined
  hovered: boolean
  onMapButtonClick: (stream: Stream) => void
  onClick: (event: React.MouseEvent<HTMLAnchorElement>, stream: Stream) => void
  onPointerOver: (stream: Stream) => void
  onDrop: (stream: Stream) => void
  onDrag: (event: DragEvent<HTMLAnchorElement>, stream: Stream) => void
}

export default function StreamItem({
  stream,
  position,
  hovered,
  onMapButtonClick,
  onClick,
  onPointerOver,
  onDrop,
  onDrag,
}: StreamItemProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  const positionMapButton = (
    <Map20Regular
      data-testid={stream.metadata.bearerName + 'MapButton'}
      className={mapButtonClassName(position)}
      onClick={() => position && onMapButtonClick(stream)}
    />
  )

  const noPositionAvailableMapButton = (
    <Tooltip content={t('streams.no-position')} relationship="label">
      {positionMapButton}
    </Tooltip>
  )

  return (
    <NavLink
      data-testid={stream.metadata.bearerName}
      id="streamItem"
      key={stream.id}
      tabIndex={-1}
      to={`/streams/${stream.id}`}
      color={tokens.colorBrandForeground1}
      className={className()}
      title={stream.metadata.bearerName}
      draggable={true}
      onClick={(event) => onClick(event, stream)}
      onPointerOver={() => onPointerOver(stream)}
      onDrop={() => onDrop(stream)}
      onDrag={(event) => onDrag(event, stream)}
      onDragOver={(event) => {
        event.preventDefault()
      }}
    >
      <Avatar size={24} name={stream.metadata.bearerName} />
      <Text className={styles.name}>{stream.metadata.bearerName}</Text>
      <TimeLabel timestamp={stream.metadata.triggerTimestamp} />
      {/* Child of NavLink so no interactive elements allowed,
      thus using icon instead of button */}
      {position ? positionMapButton : noPositionAvailableMapButton}
    </NavLink>
  )

  function className() {
    let classes = styles.container
    if (hovered) {
      classes = mergeClasses(classes, styles.hovered)
    }
    return classes
  }

  function mapButtonClassName(position: Position | undefined) {
    let classes = mergeClasses(styles.mapButton, 'mapButton')
    if (position !== undefined) {
      classes = mergeClasses(classes, styles.enabledMapButton)
    }
    return classes
  }
}
